<template>
  <div>
    <b-card
      no-body
      class="custom-border-top-1px"
    >
      <b-card-header
        class="cursor-pointer user-select-none"
        :class="isShowListActivity ? '' : 'bg-collapsed-header'"
        @click="isShowListActivity = !isShowListActivity"
      >
        <b-badge
          variant="light-primary"
          class="font-small-4 px-1 py-50"
        >
          LỊCH SỬ HOẠT ĐỘNG
        </b-badge>
        <feather-icon
          :icon="isShowListActivity ? 'ChevronUpIcon' : 'ChevronDownIcon'"
          size="18"
          class="cursor-pointer"
        />
      </b-card-header>
      <template
        v-if="listActivity.length && isShowListActivity"
      >
        <b-table
          ref="refUsersListTable"
          class="position-relative px-2 table-list-activity"
          responsive
          primary-key="id"
          show-empty
          bordered
          small
          hover
          empty-text="Không có kết quả nào"
          :items="activities"
          :fields="tableColumns"
          @row-clicked="openModalDetailChange"
        >
          <template #cell(thoi-gian)="data">
            <div
              class="font-small-3 text-nowrap"
              :class="'text-' + data.item['thao-tac'].variant"
            >
              {{ data.item['thoi-gian'] }}
              <br>
              <span class="text-center font-small-2 text-secondary">{{ data.item['hours'] }}</span>
            </div>
          </template>

          <template #cell(nguoi-thao-tac)="data">
            <div
              class="text-uppercase cursor-pointer"
              :class="'text-' + data.item['thao-tac'].variant"
              @click="goReportUser(data.item['nguoi-thao-tac'].userId)"
            >
              <b-avatar
                size="32"
                :src="data.item.avatar"
                :text="avatarText(data.item.fullname)"
                :variant="`light-${resolveUserRoleVariant(data.item.role)}`"
              /> &nbsp;
              {{ data.item['nguoi-thao-tac'] }}
            </div>
          </template>

          <template #cell(thao-tac)="data">
            <div
              class="text-nowrap"
              :class="'text-' + data.item['thao-tac'].variant"
            >
              <feather-icon
                size="16"
                :icon="data.item['thao-tac'].icon"
              />
              |
              {{ data.item['thao-tac'].text }}
            </div>
          </template>

          <template #cell(doi-tuong)="data">
            <div
              :class="'text-' + data.item['thao-tac'].variant"
            >
              {{ data.item['doi-tuong'].text }}
            </div>
          </template>

          <template #cell(chi-tiet)="data">
            <div
              class="updated-list"
            >
              <div
                v-for="(item, idx) in data.item['chi-tiet'].updated.slice(0, 1)"
                :key="'updated-item-' + idx"
                class="updated-item"
              >
                <span class="font-small-3 data-text text-primary minw-110px">
                  {{ item.text }}
                </span>
                <div class="max-3-line">
                  <span
                    v-if="item.old !== null && item.old !== undefined && item.old !== '0 đ'"
                    class="font-small-3 data-old"
                  >
                    {{ item.old }}
                  </span>
                  <feather-icon
                    v-if="item.old !== null && item.old !== undefined && item.old !== '0 đ'"
                    icon="ArrowRightIcon"
                    class="icon-change text-primary"
                  />
                  <span
                    class="font-small-3 data-new"
                  >
                    {{ item.new }}
                  </span>
                </div>
              </div>
              <small
                v-if="data.item['chi-tiet'].updated.length > 1"
                class="text-secondary"
              >
                ... (nhấn để xem toàn bộ thay đổi)
              </small>
            </div>
          </template>

        </b-table>
      </template>
      <div
        v-else-if="isShowListActivity"
        class="px-2 pb-2 w-100 pt-2"
      >
        Không tìm thấy kết quả chỉnh sửa nào
      </div>
      <div
        v-else
        class="px-2 pb-2 w-100 pt-2"
        @click="isShowListActivity = !isShowListActivity"
      >
        Nhấn để xem chi tiết!
      </div>
    </b-card>

    <b-modal
      id="modal-detail-change"
      ref="modal-detail-change"
      ok-title="Ok"
      centered
      ok-only
      size="lg"
      header-text-variant="primary"
      :title="'Chi tiết thay đổi (click vào nội dung để sao chép)'"
    >
      <b-card-text>
        <template>
          <div
            v-if="modalData['thao-tac'] && modalData['nguoi-thao-tac'] && modalData['thoi-gian']"
            class="d-flex"
          >
            <div
              class="text-nowrap mr-2"
              :class="'text-' + modalData['thao-tac'].variant"
            >
              {{ modalData['thoi-gian'] }}
            </div>
            <div
              class="text-uppercase mr-2"
              :class="'text-' + modalData['thao-tac'].variant"
            >
              {{ modalData['nguoi-thao-tac'] }}
            </div>
            <div
              class="text-nowrap mr-2"
              :class="'text-' + modalData['thao-tac'].variant"
            >
              <feather-icon
                size="16"
                :icon="modalData['thao-tac'].icon"
              />
              |
              {{ modalData['thao-tac'].text }}
              <template v-if="modalData['thao-tac'].text === ACTION_TYPE.login.text">
                <br>
                <strong>{{ modalData['doi-tuong'].text }}</strong>
              </template>
            </div>
          </div>
          <hr>
          <div
            v-for="(item, idx) in (modalData['chi-tiet'] && modalData['chi-tiet'].updated)"
            :key="'updated-item-' + idx"
            class="updated-item border-bottom pb-25 mb-25"
          >
            <span class="font-small-3 data-text text-primary minw-110px">
              {{ item.text }}
            </span>
            <span
              v-if="item.old !== null && item.old !== undefined && item.old !== '0 đ'"
              class="font-small-3 data-old"
              @click="handleCopy"
            >
              {{ item.old }}
            </span>
            <feather-icon
              v-if="item.old !== null && item.old !== undefined && item.old !== '0 đ'"
              icon="ArrowRightIcon"
              class="icon-change text-primary"
            />
            <span
              class="font-small-3 data-new"
              @click="handleCopy"
            >
              {{ item.new }}
            </span>
          </div>
        </template>
      </b-card-text>
    </b-modal>

  </div>
</template>

<script>
import {
  BAvatar,
  BBadge, BCard, BCardHeader, BCardText, BTable, VBToggle,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { getUserData } from '@/auth/utils'
import { avatarText } from '@core/utils/filter'
import { ref } from '@vue/composition-api'

const TYPE = {
  create: {
    text: 'Tạo mới',
    icon: 'FilePlusIcon',
    variant: 'success',
  },
  update: {
    text: 'Cập nhật',
    icon: 'EditIcon',
    variant: 'primary',
  },
  download: {
    text: 'Tải xuống',
    icon: 'DownloadIcon',
    variant: 'warning',
  },
  delete: {
    text: 'Xóa',
    icon: 'XSquareIcon',
    variant: 'danger',
  },
  view: {
    text: 'Xem hồ sơ',
    icon: 'EyeIcon',
    variant: 'success',
  },
  login: {
    text: 'Đăng nhập',
    icon: 'LogInIcon',
    variant: 'primary',
  },
}

const OBJECT = {
  contract: {
    text: 'HS/HĐ',
    variant: '',
    keyData: '',
  },
  debt_collection: {
    text: 'thu nợ',
    variant: '',
    keyData: '',
  },
  assign_team: {
    text: 'chuyển đội',
    variant: '',
    keyData: '',
  },
  assign_creator: {
    text: 'chuyển PKH',
    variant: '',
    keyData: '',
  },
  contract_approve_status: {
    text: 'xác minh',
    variant: '',
    keyData: '',
  },
  debt_collection_consultant: {
    text: 'thu phí tư vấn',
    variant: '',
    keyData: '',
  },
  debt_collection_plan: {
    text: 'kế hoạch thu nợ',
    variant: '',
    keyData: '',
  },
  contract_status: {
    text: 'trạng thái',
    variant: '',
    keyData: '',
  },
  comment_note: {
    text: 'ghi chú',
    variant: '',
    keyData: '',
  },
  comment_task: {
    text: 'tác vụ',
    variant: '',
    keyData: '',
  },
  document: {
    text: 'tài liệu',
    variant: '',
    keyData: '',
  },
  sms: {
    text: 'sms',
    variant: '',
    keyData: '',
  },
  document_name: {
    text: 'tên tài liệu',
    variant: '',
    keyData: '',
  },
  contract_verify_info: {
    text: 'kết quả điều tra & phê duyệt',
    variant: '',
    keyData: '',
  },
  image: {
    text: 'hình ảnh',
    variant: '',
    keyData: '',
  },
  file: {
    text: 'file',
    variant: '',
    keyData: '',
  },
  exchange: {
    text: 'Tài khoản',
    variant: '',
    keyData: '',
  },
}

export default {
  components: {
    BAvatar,
    BCardText,
    BCard,
    BCardHeader,
    BBadge,
    BTable,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  props: {
    listActivity: {
      type: Array,
      default: () => [],
      required: true,
    },
    viewMode: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      modalData: {},
      ACTION_TYPE: TYPE,
      ACTION_OBJECT: OBJECT,
    }
  },
  computed: {
    // format data for table
    activities() {
      if (!this.listActivity.length) return []

      const list = []
      this.listActivity.forEach(item => {
        const raw = { ...item }
        const time = this.formatDate(raw.created_at, true) || ''

        const activity = {
          fullname: raw.user.fullname,
          avatar: raw.user.avatar,
          userId: raw.user.id,
          user: {},
          'thoi-gian': time.split(' ')[0],
          hours: `${time.split(' ')[1].replace(':', 'h ')}p`,
          'nguoi-thao-tac': raw.user.fullname,
          'thao-tac': TYPE[raw.action],
          'doi-tuong': OBJECT[raw.object_type],
          'chi-tiet': {},
        }

        let focusData = ''
        const rawData = raw.object_data || {}
        const oldData = rawData && rawData.old_data ? rawData.old_data : {}
        const newData = rawData && rawData.new_data ? rawData.new_data : rawData

        if (rawData && !rawData.new_data && !rawData.old_data) {
          focusData = rawData
        }

        const isLogin = raw.action === 'login' && raw.object_type === 'exchange'

        const isViewContract = raw.action === 'view' && raw.object_type === 'contract'

        const isCreateContract = raw.action === 'create' && raw.object_type === 'contract'

        const isUpdateDocumentFiles = raw.action === 'update' && ((raw.object_type === 'contract'
            && (oldData?.document_files?.length !== newData?.document_files?.length)) || raw.object_type === 'document')

        if (isUpdateDocumentFiles) {
          const dc = this.formatUpdateDocumentFiles(oldData, newData)
          activity['chi-tiet'] = {
            updated: dc.length ? dc.map(f => ({
              old: f.type === 'old' ? f.filename : 'Thêm mới',
              new: f.type === 'new' ? f.filename : 'Đã xóa',
              key: 'Tài liệu đính kèm',
              text: 'Tài liệu đính kèm',
            })) : [],
          }
          list.push(activity)
          return
        }

        const flatOldObject = this.flattenObject(oldData || {})
        const flatNewObject = this.flattenObject(focusData || newData || {})
        const flatDiff = this.diffFlatten(flatNewObject, flatOldObject)
        const [updated, removed] = flatDiff

        const formatActivityData = (key, keyString) => {
          // vietsub
          const res = this.KEY_TO_TEXT[keyString]
          if (res) return res

          // format money
          if (['original_amount',
            'interest_amount',
            'fee_to_buy_original',
            'fee_to_buy_interest',
            'amount_of_refund',
            'amount_of_break_contract',
            'fee_to_break_contract',
            'fee_for_consultant',
            'fee_for_consultant_input',
            'fee_for_consultant_1',
            'fee_for_consultant_contract',
            'fee_for_consultant_2',
            'fee_for_consultant_service',
            'fee_for_consultant_3',
            'total_debt_collection_amount',
            'amount_to_buy_interest',
            'amount_to_buy_original',
            'final_amount_for_team',
            'amount_for_team',
            'price',
            'syndicate_amount',
            'driver_amount',
            'return_driver_amount',
            'team_fund',
            'amount',
            'keep_amount',
            'debt_type',
          ].includes(key)) {
            return `${this.formatNumber(keyString)} đ`
          }

          // format date
          if (this.isValidDate(keyString)) return this.formatDate(keyString)

          return keyString
        }

        const compareDiff = Object.keys((rawData && !newData && !oldData) ? removed : updated)
          .map(keyUpdated => ({
            old: formatActivityData(keyUpdated, flatOldObject[keyUpdated]),
            new: formatActivityData(keyUpdated, flatNewObject[keyUpdated]),
            text: keyUpdated.split('.')
              .map(key => this.KEY_TO_TEXT[key] || key)
              .join('->'),
            key: keyUpdated,
          }))
          .filter(keyUpdated => {
            const arrKeyNotShow = ['authenticator_ids']
            let showMe = true
            arrKeyNotShow.forEach(keyNotShow => {
              if (keyUpdated.key.startsWith(keyNotShow)) {
                showMe = false
              }
            })
            if (keyUpdated.key.endsWith('_id') || keyUpdated.key.endsWith('_ids')) {
              showMe = false
            }
            return showMe
          })

        const removeKeys = ['id', 'user_id', 'department_id']

        Object.assign(activity['chi-tiet'], {
          updated: compareDiff.filter(_item => !removeKeys.includes(_item.key)),
          removed,
        })

        if (activity['chi-tiet'].updated.length && !isCreateContract) {
          list.push(activity)
        }

        if (isViewContract) {
          list.push(activity)
        }

        if (isLogin) {
          activity['doi-tuong'].text = `[Thiết bị] ${raw.device_name}`
          list.push(activity)
        }
      })
      return list
    },
  },
  methods: {
    handleCopy(e) {
      const text = e.currentTarget && e.currentTarget.outerText
      if (text) {
        navigator.clipboard.writeText(text)
        this.$showNoti('Đã sao chép!')
      }
    },
    formatUpdateDocumentFiles(oldData, newData) {
      const hash = {}

      if (oldData.document_files && oldData.document_files.length) {
        oldData.document_files.forEach(file => {
          hash[file.filename] = hash[file.filename]
            ? {
              ...hash[file.filename],
              count: hash[file.filename].count + 1,
            }
            : {
              filename: file.filename,
              type: 'old',
              obj: file,
              count: 1,
            }
        })
      }

      if (newData.document_files && newData.document_files.length) {
        // eslint-disable-next-line no-unused-expressions
        newData.document_files?.forEach(file => {
          hash[file.filename] = hash[file.filename]
            ? {
              ...hash[file.filename],
              count: hash[file.filename].count + 1,
            }
            : {
              filename: file.filename,
              type: 'new',
              obj: file,
              count: 1,
            }
        })
      }

      return Object.values(hash)
        .filter(file => {
          const hFile = hash[file.filename]
          return hFile.count === 1
        })
    },
    openModalDetailChange(modalData) {
      this.modalData = modalData
      this.$refs['modal-detail-change'].show()
    },
    goReportUser(userId = '') {
      if (this.viewMode === 'reports-user') return

      const userData = getUserData()
      if (userId === userData.id) {
        this.$router.push({
          name: 'reports-me',
        })
      } else {
        this.$router.push({
          name: 'reports-user',
          params: { id: userId },
          query: {
            monthFilter: this.monthFilter,
            yearFilter: this.yearFilter,
            t: new Date().getTime(),
          },
        })
      }
    },
  },
  setup() {
    const tableColumns = [
      {
        label: 'Thời gian',
        key: 'thoi-gian',
      },
      {
        label: 'Người thao tác',
        key: 'nguoi-thao-tac',
      },
      {
        label: 'Thao tác',
        key: 'thao-tac',
      },
      {
        label: 'Đối tượng',
        key: 'doi-tuong',
      },
      {
        label: 'Chi tiết',
        key: 'chi-tiet',
      },
    ]
    const resolveUserRoleVariant = role => {
      if (role === 'user') return 'primary'
      if (role === 'admin') return 'danger'
      return 'primary'
    }
    const isShowListActivity = ref(false)

    return {
      isShowListActivity,
      tableColumns,
      avatarText,
      resolveUserRoleVariant,
    }
  },
}
</script>

<style lang="scss">
.updated-item {
  display: grid;
  grid-template-columns: 1fr auto auto auto;

  .data-new {
    font-weight: bolder;
    max-height: 300px;
    overflow-y: scroll;
  }

  .data-old {
    max-height: 300px;
    overflow-y: scroll;
  }

  .icon-change {
    margin: 0 .25rem;
  }
}
</style>
