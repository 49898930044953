<template>
  <div>
    <b-card
      no-body
      class="sticky-top-65"
    >
      <b-card-header class="justify-content-between gap-2 py-1">
        <div class="d-flex align-items-end">
          <b-badge
            variant="light-primary"
            class="font-small-4 px-1 py-50"
          >
            BÁO CÁO NHÂN VIÊN
          </b-badge>
          <span class="ml-1 font-weight-bolder font-medium-1">{{ reportGeneralTitle }}</span>
        </div>
        <div class="d-flex flex-wrap">
          <div class="d-flex mr-1 gap-1 align-items-center">
            <label class="text-nowrap">Tháng</label>
            <v-select
              v-model="monthFilter"
              class="select-size-sm"
              label="name"
              :options="$monthOptions"
              :searchable="false"
              :clearable="true"
            />
          </div>
          <div class="d-flex gap-1 align-items-center">
            <label class="text-nowrap">Năm</label>
            <v-select
              v-model="yearFilter"
              class="select-size-sm"
              label="name"
              :options="$yearOptions"
              :searchable="false"
              :clearable="false"
            />
          </div>
        </div>
      </b-card-header>
    </b-card>

    <Overview
      :data="userSumData"
      :user-rating="userRating"
    />

    <reports-debts
      v-if="userSumData.transactions"
      :report-debts="userSumData.transactions.data"
    />

    <list-activity
      v-if="ability().can('read', 'Activities')"
      :list-activity="userListActivity"
      :view-mode="'reports-user'"
    />
  </div>
</template>

<script>
import {
  BCard, BCardHeader, VBTooltip, BBadge,
} from 'bootstrap-vue'
import store from '@/store'
import { onUnmounted } from '@vue/composition-api'
import Overview from '@/views/reports/user/Overview.vue'
import ReportsDebts from '@/views/reports/user/Debts.vue'
import reportStoreModule from '@/views/reports/reportStoreModule'
import vSelect from 'vue-select'
import useReportsOverview from '@/views/reports/overview/useReportsOverview'
import departmentStoreModule from '@/views/settings/departments/departmentStoreModule'
import ListActivity from '@/views/reports/user/ListActivity.vue'
import ability from '@/libs/acl/ability'
import { subject } from '@casl/ability'

export default {
  components: {
    ListActivity,
    ReportsDebts,
    BCard,
    BCardHeader,
    Overview,
    BBadge,
    vSelect,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      ability,
      subject,
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler() {
        const q = this.$route.query
        this.reportMode = this.$route.name
        this.userId = this.$route.params.id
        if (q.monthFilter !== undefined && q.yearFilter !== undefined) {
          this.monthFilter = q.monthFilter || ''
          this.yearFilter = q.yearFilter || ''
        }
      },
    },
    reportGeneralTitle() {
      const q = this.$route.query
      const p = this.$route.params
      if (this.monthFilter !== q.monthFilter || this.yearFilter !== q.yearFilter) {
        this.$router.push({
          name: 'reports-user',
          params: { id: p.id },
          query: { monthFilter: this.monthFilter, yearFilter: this.yearFilter, t: new Date().getTime() },
        })
      }
    },
  },
  created() {
    // const userData = getUserData()
    // if (userData?.department?.code?.toLowerCase() === 'pkh') {
    //   this.goReportUserPKH({
    //     id: userData.id,
    //     name: userData.fullname,
    //   })
    // }
  },
  methods: {
    goReportUserPKH(d) {
      this.$router.push({
        name: 'reports-user-pkh',
        params: { id: d.id },
        query: {
          name: d.name, type: 'user-pkh', monthFilter: this.monthFilter, yearFilter: this.yearFilter, t: new Date().getTime(),
        },
      })
    },
  },
  setup() {
    const REPORT_STORE_MODULE_NAME = 'report'
    const DEPARTMENT_STORE_MODULE_NAME = 'department'
    if (!store.hasModule(DEPARTMENT_STORE_MODULE_NAME)) store.registerModule(DEPARTMENT_STORE_MODULE_NAME, departmentStoreModule)
    if (!store.hasModule(REPORT_STORE_MODULE_NAME)) store.registerModule(REPORT_STORE_MODULE_NAME, reportStoreModule)
    onUnmounted(() => {
      if (store.hasModule(DEPARTMENT_STORE_MODULE_NAME)) store.unregisterModule(DEPARTMENT_STORE_MODULE_NAME)
      if (store.hasModule(REPORT_STORE_MODULE_NAME)) store.unregisterModule(REPORT_STORE_MODULE_NAME)
    })

    return {
      ...useReportsOverview(),
    }
  },
}
</script>

<style lang="scss">
</style>
